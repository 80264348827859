const graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
  const client = graph.Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    }
  });

  return client;
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client
    .api('/me')
    // .api('/me','/me/photo/$value')
    .select('displayName,mail,userPrincipalName,jobTitle,officeLocation,businessPhones') 
    .get();



  return user;
}
