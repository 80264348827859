import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';
import Edit from '../pages/Edit';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/signature" component={Dashboard} isPrivate />
    <Route path="/edit" component={Edit} isPrivate />
  </Switch>
);

export default Routes;
