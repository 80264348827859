import React,{useState} from 'react';

import globais from '../../utils/globais';

import { useAuth } from '../../hooks/auth';
import logo from '../../assets/FQM_branco.png';
import fqm from '../../assets/fqm.png';
import divcom from '../../assets/divcom.png';
import farma from '../../assets/farma.png';
import grupo from '../../assets/grupo.png';
import melora from '../../assets/melora.png';
import reisen from '../../assets/reisen.png';
import consumo from '../../assets/consumo.png';
import emidia from '../../assets/emidiapubli.png';
import './styles.css'
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

// função para incluir o bem vindo no header2
function BemVindo(){
    const {accountInfo } = useAuth();
    let nome = accountInfo.user.displayName
  
    let texto  = "Bem vindo(a) "+ nome
  
    let titulo = document.querySelector("#bemVindo");
  
    let contador = 0;
    
    try{
  
      titulo.textContent += texto.charAt(contador);
  
      contador++
      
      
      if (contador>texto.length){
        contador = 0;
        titulo.textContent = ' ';
  
      };
    }
    catch{
  
    };
  };

  //funcção para gerar a assinatura de email
  function Assinatura(){
    let contador2 = 0;
    let titulo2 = document.querySelector("#tituloid");
    let texto2  = "Sua assinatura foi gerada, so copiar e colar no seu E-mail.";
  
    try{
      titulo2.textContent += texto2.charAt(contador2);
  
      contador2++  
      if (contador2>texto2.length){
        contador2 = 0;
        titulo2.textContent = ' ';
  
      };
    }
    catch{
  
    };
    
    
  };
  
  //função para gerar os endereços selecionados de cada unidade
  function Location (local){
    let locals = [
      ["BARRA",["Av. José Silva de Azevedo Neto, 200"," Condomínio O2 - Bloco I - 1o Andar ","Barra da Tijuca - Rio de Janeiro - RJ CEP: 22775-056 "]],
      ["JACARE",["Rua Viúva Cláudio, 300 ","Jacaré - Rio de Janeiro - RJ - CEP: 20970-032",""]],
      ["RJ-PAVUNA",["Rua Herculano Pinheiro, 153"," Armazém 5C ","Pavuna - Rio de Janeiro - RJ - CEP: 21532-440"]],	
      ["SP",["Rua Dr. Renato Paes de Barros, 750 ","Conjunto 61/62/63 ","Itaim Bibi - São Paulo - BARRA CEP: 04530-001"]],
      ["RECIFE",["Av. Engenheiro Antônio de Goés, 275 ","Sala 903 – Edifício internacional Trade Center ","Pina - Recife - PE CEP: 51110-000 "]],
      ["PE-POMBOS",["BR 232 - KM 63 "," Lot. Parque Industrial "," Pombos - Pernambuco - PE - CEP: 556300-000 "]],
      ["CAMPINA GRANDE",["Av. Senador Argemiro de Figueiredo "," 2113 – B Galpão 3° ","Itararé - Campina grande - PB - CEP: 58411-020 "]]
    ];
    for (let cont = 0;cont < locals.length ; cont++ ){
      if (locals[cont][0]===local.toUpperCase()){
       return locals[cont][1];
       
      
      };
            
    };
        
   
  };


//função para receber as logos das empresas e divisões, baseados nos e-mails 
  function Image(){
    const { accountInfo } = useAuth();
    let email = accountInfo.user.email;
    // let email = "elisboa@grupo.com.br";
    // imgLogo
    email = email.split("@")[1];
    email = email.split(".")[0];
    let teste = [["fqm",fqm],["emidiapubli",emidia],["fqmmelora",melora],["fqmfarma",farma],["fqmconsumo",consumo],["reisenviagens",reisen]]
    
    for (var i=0; i< teste.length ; i++){
        if (email === teste[i][0]){
            return teste[i][1]
        }
    }
    
  }
const Dashboard = () => {
  const { accountInfo } = useAuth();
  // const Navigate = useNavigate();


  return (
    <div>

      

        <div className="headerAss">
            <img  className="Logo" src={logo} alt=""/>
            <h1> Assinatura de E-mail </h1>
        </div>
        
        <div className="header2Dash">
          
                <h3>Bem vindo(a): {accountInfo.user.displayName}</h3>
           
            {/* <h3 id="bemVindo"></h3> */}
            
        </div>




        <h3 className="titulo" id="tituloid"> </h3>




        <div className="containerAss">
            
            <div className="box">
                
                <fieldset id="Assinatura" style={{'width':'820px'}}>
                    <br/>
                    <table className="table1">
                        <tbody>
                            <tr>
                                <td className="td1" >
                                    <span className="lblNome">{accountInfo.user.displayName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="label" >{accountInfo.user.title}</span>
                                </td>

                            </tr>
                                        
                            <tr>
                                <td style={{'textAlign':'left','fontFamily':'Century Gothic', 'color':'white','fontSize':'11px'}}>.<br/>
                                </td>
                            </tr>


                        </tbody>
                    </table>

                    <table className="table1">

                        <tbody><tr>
                            <td>
                                <img className="imgLogo" alt="" src={Image()} style={{'borderWidth':'0px'}}/>
                            </td>
                            <td width="10px">&nbsp;</td>
                            <td width="100%">
                                <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                                    <tbody><tr>
                                        <td>
                                            <span className="label2" >{globais.telefone}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="label" >{Location(globais.local)[0]}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="label">{Location(globais.local)[1]}</span>
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="label">{Location(globais.local)[2]}</span>
                                        </td>                            
                                    </tr>
                                    
                                </tbody></table>
                                                            
                            </td>
                        </tr>
                        
                    </tbody>
                    </table>
                    <table className="table2" >
                                    <tbody><tr>
                                        <td style={{'textAlign': 'left', 'fontFamily': 'Century Gothic', 'color': 'white', 'fontSize': '5px'}}>.<br/>
                                                                        
                                    </td></tr>

                            <tr>
                                <td className="td6" >
                                {/* <span className="lblRodape" >Uma empresa do FQM Grupo.</span> */}
                            </td>
                        </tr>
                    </tbody>
                    </table>
                    <br/>
                </fieldset>
            </div>

            <br/>



            <table className="table3" >
                <tbody>
                    <tr>
                        <td width="311">
                            <div className="col-md-3 col-sm-3 col-xs-6"> 
                            

                                <Link to="/edit" className="btn btn-sm animated-button thar-three">
    
                                    Editar

                                </Link>
                                
                            
                            </div>
                            {/* <!-- <div className="col-md-3 col-sm-3 col-xs-6"> <a href="javascript:Copiar()" className="btn btn-sm animated-button thar-three">Copiar</a> </div> --> */}

                        </td>
                        

                    </tr>
                </tbody>
            </table>

        </div>



    </div>

  );
  Assinatura();
};

export default Dashboard;
