import React,{useState} from 'react';

import globais from '../../utils/globais';

import { useAuth } from '../../hooks/auth';

import logo from '../../assets/FQM_branco.png';
import './styles.css'
import { Link } from 'react-router-dom';
 function salvar(tel,locals){
        globais.telefone = tel;
        globais.local = locals;
    };
const Edit = () => {
  // const { accountInfo } = useAuth();
  const {accountInfo } = useAuth();
  const [tel,setTel]= useState(accountInfo.user.phone);
    const [locals,setLocals]= useState(accountInfo.user.address);
   
  

   
  return (
    <div>

      <div className="headerAss">
          <img  className="Logo" src={logo} alt=""/>
          <h1> Assinatura de E-mail </h1>
      </div>
      <div className="header2">
        <h3>Bem vindo(a): {accountInfo.user.displayName}</h3>
      </div>
      <div className="tabela" >
          <table border="0" cellPadding="5" cellSpacing="5" style={{"width":"100%"}}>
              <tbody className="grade" >
                  <tr className="tr1">
                      <td className="td" >
                          <span id="nome">Nome: 

                          </span>
                      </td>
                      <td className="td" >
                          <input name="nameInputNome" value={accountInfo.user.displayName} type="text"  id="inputNome" style={{"width":"330px"}} placeholder="Digite seu nome completo" disabled = "disabled"/>
                      </td>
                  </tr>    
                  <tr className="tr1">
                      <td className="td">
                          <span id="cargo">Cargo: 

                          </span>
                      </td>
                      <td className="td" >
                          <input name="nameInputCargo" value={accountInfo.user.title}  disabled = "disabled" type="text" placeholder="Digite seu cargo"  id="inputCargo" style={{"width":"330px"}}/>
                      </td>
                  </tr>                           
                  <tr className="tr1">
                      <td className="td" >
                          <span id="telefone">Telefone: 

                          </span>
                      </td>
                      <td className="td">
                          <input name="nameInputTelefone" type="text" placeholder="Digite seu telefone" value={tel} id="inputTelefone" style={{"width":"330px"}} onChange={(e)=>setTel(e.target.value)}/>
                      </td>
                  </tr>
                  <tr className="tr1">
                      <td className="td" >
                          <span id="endereco">Endereço: 

                          </span>
                      </td>
                      <td className="td" >
                          <select value={locals} onChange={(e)=>setLocals(e.target.value)} name="Cidade" id="selectCidade" >
                              <option  value="BARRA" id="BARRA">RJ-BARRA</option>
                              <option  value="JACARE" id="JACARE">RJ-JACARE</option>
                              {/* <!-- <option value="RJ-PAVUNA">RJ-PAVUNA</option> --> */}
                              <option  value="SP" id="SP">SP-ITAIM BIBI</option>
                              <option  value="RECIFE" id="RECIFE">PE-RECIFE</option>
                              <option value="PE-POMBOS">PE-POMBOS</option>
                              <option  value="CAMPINA GRANDE" id="CAMPINA GRANDE">PB-CAMPINA GRANDE</option>

                          </select>
                      </td>
                  </tr>

                  <tr className="tr1">
                      <td className="td" >
                      
                      <div  className="col-md-3 col-sm-3 col-xs-6"> 
                        {/* Fazer validação caso não apresente o NOME e CARGO, retornar mensagem que
                        a integração não ocorreu ainda */}

                                <Link onClick={salvar(tel,locals)} to="/signature" className="btn btn-sm animated-button thar-three">

                                Gerar

                                </Link>
                            
                            
                        
                        </div>
      
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>



    </div>
  );
};

export default Edit;
