import React, { useCallback } from 'react';

import { useAuth } from '../../hooks/auth';

import logo from '../../assets/FQM_branco.png';
import './styles.css'

const SignIn = () => {
  const { signIn } = useAuth();

  const handleSignIn = useCallback(() => {
    const accessToken = localStorage.getItem('@AzureAd:accessToken');

    if (!accessToken) {
      signIn();
    }
  }, [signIn]);

  return (
    <div className="AppSign">
      <div className='AppContentLeft'>

        {/* <div className='container'>
        </div> */}


          <div className="stars" ></div>
          <div class="stars2" ></div>
          <div className="stars3" ></div>
          <div className="stars4" ></div>
          <div className="stars5" ></div>

      <img src={logo} alt="FQM" className="AppContentLogo" />
        
      </div>
      <div className='AppContentRight'>
          <h1>Seja bem vindo(a) a nova assinatura de e-mail FQM</h1>
          <button className='buttonSign' type="button" onClick={handleSignIn}>Iniciar</button>
      </div>
    </div>
  );
};

export default SignIn;